import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AuthService } from '@services/auth/auth.service';
import { UserVerificationCodeType } from '@models/sign-up/user-verification-code-type.enum';
import { finalize } from 'rxjs';
import { CodeInputComponent } from '@modules/shared/components/code-input/code-input.component';
import { LoginRequest } from '@models/users/dto/login.request';
import { User } from '@models/users/user.model';
import { FormsModules } from '@utils/shared-modules';
import { EventUpdateRequest } from '@models/events/dto/event-update.request';
import { EventStatus } from '@models/events/event-status.enum';
import { EventRSVPRequest } from '@models/events/dto/event-rsvp.request';
import { NewUserCreationRequest } from '@models/users/dto/new-user-creation.request';
import { CountdownFormatPipe } from '@modules/sign-up/components/verify-phone-step/verify-phone-step.component';

@Component({
  selector: 'app-verify-phone-dialog',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    CodeInputComponent,
    FormsModules,
    CountdownFormatPipe,
  ],
  templateUrl: './verify-phone-dialog.component.html',
  styleUrl: './verify-phone-dialog.component.scss',
})
export class VerifyPhoneDialogComponent {
  resendInProgress = false;
  resendSuccess = false;
  isResendInCooldown = false;

  timeLeft = 90;
  interval: any;

  @Input() isWrongCode = false;
  @Input() otpUserId?: number;

  @Input() phone?: string;
  @Input() user?: User;
  @Input() eventUuid?: string;
  @Input() eventId?: number;

  @Input() isAtPublish: boolean = false;

  @Input() createOrFindReq?: NewUserCreationRequest;
  @Input() rsvpReq?: EventRSVPRequest;
  @Input() isRSVPwithOTP: boolean = false;

  @Output() isAtPublishEvent = new EventEmitter<any>();
  @Output() isSaveRSVPEvent = new EventEmitter<any>();
  @Output() authLoginEvent = new EventEmitter<any>();
  @Output() changePhone = new EventEmitter();
  @Output() onCodeChange: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('codeInput') codeInput!: CodeInputComponent;

  constructor(private authService: AuthService) {
    this.startTimer();
    this.isResendInCooldown = true;
    this.resendSuccess = true;
    setTimeout(() => (this.resendSuccess = false), 2000);
  }

  onCodeChanged(code: string) {
    this.isWrongCode = false;
    this.onCodeChange.emit(true);
  }

  navigateBack() {
    this.changePhone.emit();
  }
  onCodeCompleted(code: string) {
    const loginRequest: LoginRequest = {
      phone: this.user?.phone || this.phone,
      isAfterPublish: this.isAtPublish,
      otp: code,
    };

    if (this.isAtPublish) {
      const request: EventUpdateRequest = {
        status: EventStatus.ACTIVE,
      };

      request.newUser = {
        id: this.user?.id,
        eventUuid: this.eventUuid,
      };
      this.isAtPublishEvent.emit({
        req: request,
        loginRequest: loginRequest,
      });
    } else if (this.isRSVPwithOTP) {
      this.isSaveRSVPEvent.emit({
        req: this.rsvpReq,
        loginRequest: loginRequest,
      });
    } else {
      this.authLoginEvent.emit({ loginRequest: loginRequest, logUser: true });
    }
  }

  resendSms() {
    if (
      (!this.user?.id && !this.otpUserId) ||
      this.isResendInCooldown ||
      this.resendInProgress
    ) {
      return;
    }

    this.resendInProgress = true;

    this.authService
      .resendVerificationCode(
        this.user ? this.user.id : this.otpUserId || 0,
        UserVerificationCodeType.PHONE,
      )
      .pipe(finalize(() => (this.resendInProgress = false)))
      .subscribe({
        next: () => {
          this.startTimer();
          this.isResendInCooldown = true;
          this.resendSuccess = true;
          setTimeout(() => (this.resendSuccess = false), 2000);
          this.codeInput.reset();
        },
        error: (err) => {
          if (err?.message === 'cooldown time has not passed') {
            this.startTimer();
            this.isResendInCooldown = true;
          }
        },
      });
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        clearInterval(this.interval);
        this.timeLeft = 90;
        this.isResendInCooldown = false;
      }
    }, 1000);
  }
}
