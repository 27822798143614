<div class="toggle-container">
  <div
    class="toggle-button"
    [class.active]="selectedOption === 'phone'"
    (click)="selectOption('phone')"
  >
    {{ 'APP.OPTION_SELECTION_TOGGLE.PHONE' | translate }}
  </div>
  <div
    class="toggle-button"
    [class.active]="selectedOption === 'email'"
    (click)="selectOption('email')"
  >
    {{ 'APP.OPTION_SELECTION_TOGGLE.EMAIL' | translate }}
  </div>
</div>

