<ng-container class="auth-step-page-center">
  <h1 class="dialog-title">{{ "APP.AUTH.ENTER_PHONE_CODE" | translate }}</h1>
  <div class="subheader subheader-sm">
    {{ "APP.AUTH.ENTER_PHONE_CODE_SUBHEADER" | translate }}
    <span>{{ user?.phone || phone }}. </span>
    <span class="change-to-email" (click)="navigateBack()">{{
      "APP.AUTH.CHANGE_PHONE" | translate
    }}</span>
  </div>

  <div class="body">
    <div></div>
    <form class="form code-input-form">
      <app-code-input
        #codeInput
        class="code-input"
        [codeLength]="6"
        [hasError]="isWrongCode"
        (codeChanged)="onCodeChanged($event)"
        (codeCompleted)="onCodeCompleted($event)"
      >
      </app-code-input>

      @if (isWrongCode) {
        <div class="mb-20">
          @if (resendSuccess) {
            <div class="text-success">
              {{ "APP.AUTH.SUCCESS.SMS_RESENT" | translate }}
            </div>
          } @else {
            <mat-error>
              {{ "APP.AUTH.ERRORS.WRONG_CODE" | translate }}
            </mat-error>
          }
        </div>

        <div class="action-btn-container">
          <button
            type="button"
            class="btn btn-primary"
            (click)="resendSms()"
            [disabled]="resendInProgress || isResendInCooldown"
          >
            {{ "APP.RESEND_SMS" | translate }}
          </button>
        </div>
      } @else {
        <div class="resend-wrapper">
          <span class="text-link" (click)="resendSms()">
            {{ "APP.RESEND" | translate }}
          </span>
          <span class="text-description">{{
            "APP.AUTH.VIA_SMS" | translate
          }}</span>
        </div>
      }
      @if (isResendInCooldown) {
        <div class="resend-code-cooldown-info">
          {{ "APP.AUTH.RESEND_CODE_WAIT_1" | translate }}
          {{ timeLeft | countdownFormat }}
          {{ "APP.AUTH.RESEND_CODE_WAIT_2" | translate }}
        </div>
      }
    </form>
    <div></div>
  </div>
</ng-container>
