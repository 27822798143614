import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  PipeTransform,
  Pipe,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { UserVerificationCodeType } from '@models/sign-up/user-verification-code-type.enum';
import { AuthService } from '@services/auth/auth.service';
import { FormsModules } from '@utils/shared-modules';
import { CodeInputComponent } from '@modules/shared/components/code-input/code-input.component';
import { finalize } from 'rxjs';

@Pipe({
  name: 'countdownFormat',
  standalone: true,
})
export class CountdownFormatPipe implements PipeTransform {
  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    const seconds: number = value % 60;
    return `0${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }
}

@Component({
  selector: 'app-verify-phone-step',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModules,
    CodeInputComponent,
    CountdownFormatPipe,
  ],
  templateUrl: './verify-phone-step.component.html',
  styleUrl: './verify-phone-step.component.scss',
})
export class VerifyPhoneStepComponent {
  resendInProgress = false;
  resendSuccess = false;
  isResendInCooldown = false;

  timeLeft = 90;
  interval: any;

  @Input() isWrongCode = false;

  @Input() userId?: number;
  @Input() phone?: string;

  @Output() verifyPhoneSuccess: EventEmitter<string> = new EventEmitter();
  @Output() changePhone = new EventEmitter();
  @Output() onCodeChange: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('codeInput') codeInput!: CodeInputComponent;

  constructor(private authService: AuthService) {
    this.startTimer();
    this.isResendInCooldown = true;
    this.resendSuccess = true;
    setTimeout(() => (this.resendSuccess = false), 2000);
  }

  onCodeChanged(code: string) {
    this.isWrongCode = false;
    this.onCodeChange.emit(true);
  }

  onCodeCompleted(code: string) {
    this.verifyPhoneSuccess.emit(code);

    // this.authService.verifyUserViaCode(code).subscribe({
    //   next: () => {
    //     this.verifyPhoneSuccess.emit();
    //   },
    //   error: () => {
    //     this.isWrongCode = true;
    //   },
    // });
  }

  navigateBack() {
    this.changePhone.emit();
  }
  resendSms() {
    if (!this.userId || this.isResendInCooldown || this.resendInProgress) {
      return;
    }

    this.resendInProgress = true;

    this.authService
      .resendVerificationCode(this.userId, UserVerificationCodeType.PHONE)
      .pipe(finalize(() => (this.resendInProgress = false)))
      .subscribe({
        next: () => {
          this.startTimer();
          this.isResendInCooldown = true;
          this.resendSuccess = true;
          setTimeout(() => (this.resendSuccess = false), 2000);
          this.codeInput.reset();
        },
        error: (err) => {
          if (err?.message === 'cooldown time has not passed') {
            this.startTimer();
            this.isResendInCooldown = true;
          }
        },
      });
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        clearInterval(this.interval);
        this.timeLeft = 90;
        this.isResendInCooldown = false;
      }
    }, 1000);
  }
}
